import React, { useState, useEffect } from 'react';
import Modal from './Modal.js';
import logo from './media/full.gif';
import es from './media/es.png'
import tg from './media/tg.png'
import tw from './media/tw.png'
import db from './media/db.gif'
import dx from './media/dx.png'
import td from './media/td.gif'
import gif from './media/gif.gif'
import stonks from './media/stonks.gif'
import snort from './media/snort.gif'
import money from './media/money.gif'
import buy from './media/buy.gif'
import eth from './media/eth.png'
import ab from './media/about.png'
import audioFile from './media/audio.mp3';
import './App.css';

function App() {
  const [modalVisible, setModalVisible] = useState(true);
  const [audioPlaying, setAudioPlaying] = useState(false);

  // Use useEffect to show the modal when the component mounts
  useEffect(() => {
    setModalVisible(true);
  }, []);

  const closeModal = () => {
    setModalVisible(false);
    setAudioPlaying(true);
  };

  return (
    <div className="App">
      <div className='bounce'>
        <div className="el-wrap x">
          <img className="el y" src={gif}></img>
        </div>
      </div>
      <div className='bounce3'>
        <div className="lol-wrap x">
          <img className="el y" src={buy}></img>
        </div>
      </div>
      <div className='bounce1'>
        <div className="le-wrap w">
          <img className="le z" src={eth}></img>
        </div>
      </div>
      <div className='bounce4'>
        <div className="le-wrap w">
          <img className="le z" src={stonks}></img>
        </div>
      </div>
      <div className='bounce5'>
        <div className="lol-wrap x">
          <img className="le y" src={money}></img>
        </div>
      </div>
      <div className='bounce6'>
        <div className="lol-wrap y">
          <img className="le x" src={snort}></img>
        </div>
      </div>
      <div className='bounce'>
        <div className="el-wrap x">
          <img className="el y" src={gif}></img>
        </div>
      </div>
      <div className='bounce3'>
        <div className="lol-wrap x">
          <img className="el y" src={buy}></img>
        </div>
      </div>
      <div className='bounce1'>
        <div className="le-wrap w">
          <img className="le z" src={eth}></img>
        </div>
      </div>
      <div className='bounce4'>
        <div className="le-wrap w">
          <img className="le z" src={stonks}></img>
        </div>
      </div>
      <div className='bounce5'>
        <div className="lol-wrap x">
          <img className="le y" src={money}></img>
        </div>
      </div>
      <div className='bounce6'>
        <div className="lol-wrap y">
          <img className="le x" src={snort}></img>
        </div>
      </div>
      <div className='bounce'>
        <div className="el-wrap x">
          <img className="el y" src={gif}></img>
        </div>
      </div>
      <div className='bounce3'>
        <div className="lol-wrap x">
          <img className="el y" src={buy}></img>
        </div>
      </div>
      <div className='bounce1'>
        <div className="le-wrap w">
          <img className="le z" src={eth}></img>
        </div>
      </div>
      <div className='bounce4'>
        <div className="le-wrap w">
          <img className="le z" src={stonks}></img>
        </div>
      </div>
      <div className='bounce5'>
        <div className="lol-wrap x">
          <img className="le y" src={money}></img>
        </div>
      </div>
      <div className='bounce6'>
        <div className="lol-wrap x">
          <img className="le x" src={snort}></img>
        </div>
      </div>
      <div className='bounce7'>
        <div className="el-wrap x">
          <img className="el x" src={gif}></img>
        </div>
      </div>
      <div className='bounce8'>
        <div className="lol-wrap y">
          <img className="el y" src={buy}></img>
        </div>
      </div>
      <div className='bounce9'>
        <div className="le-wrap w">
          <img className="le w" src={eth}></img>
        </div>
      </div>
      <div className='bounce10'>
        <div className="le-wrap w">
          <img className="le w" src={stonks}></img>
        </div>
      </div>
      <div className='bounce11'>
        <div className="lol-wrap x">
          <img className="le x" src={money}></img>
        </div>
      </div>
      <div className='bounce12'>
        <div className="lol-wrap y">
          <img className="le y" src={snort}></img>
        </div>
      </div>
      <div className='bounce13'>
        <div className="el-wrap x">
          <img className="el x" src={gif}></img>
        </div>
      </div>
      <div className='bounce14'>
        <div className="lol-wrap x">
          <img className="el x" src={buy}></img>
        </div>
      </div>
      <div className='bounce15'>
        <div className="le-wrap w">
          <img className="le w" src={eth}></img>
        </div>
      </div>
      <div className='bounce16'>
        <div className="le-wrap w">
          <img className="le w" src={stonks}></img>
        </div>
      </div>
      <div className='bounce17'>
        <div className="lol-wrap x">
          <img className="le x" src={money}></img>
        </div>
      </div>
      <div className='bounce18'>
        <div className="lol-wrap y">
          <img className="le y" src={snort}></img>
        </div>
      </div>
      <div className='bounce19'>
        <div className="el-wrap x">
          <img className="el x" src={gif}></img>
        </div>
      </div>
      <div className='bounce20'>
        <div className="lol-wrap x">
          <img className="el x" src={buy}></img>
        </div>
      </div>
      <div className='bounce21'>
        <div className="le-wrap w">
          <img className="le w" src={eth}></img>
        </div>
      </div>
      <div className='bounce22'>
        <div className="le-wrap w">
          <img className="le w" src={stonks}></img>
        </div>
      </div>
      <div className='bounce23'>
        <div className="lol-wrap x">
          <img className="le x" src={money}></img>
        </div>
      </div>
      <div className='bounce24'>
        <div className="lol-wrap y">
          <img className="le y" src={snort}></img>
        </div>
      </div>
      <div className='bounce25'>
        <div className="el-wrap x">
          <img className="el x" src={gif}></img>
        </div>
      </div>
      <div className='bounce26'>
        <div className="lol-wrap x">
          <img className="el x" src={buy}></img>
        </div>
      </div>
      <div className='bounce27'>
        <div className="le-wrap w">
          <img className="le w" src={eth}></img>
        </div>
      </div>
      <div className='bounce28'>
        <div className="le-wrap w">
          <img className="le w" src={stonks}></img>
        </div>
      </div>
      <div className='bounce29'>
        <div className="lol-wrap x">
          <img className="le x" src={money}></img>
        </div>
      </div>
      <div className='bounce30'>
        <div className="lol-wrap z">
          <img className="le z" src={snort}></img>
        </div>
      </div>
      <div className='bounce31'>
        <div className="el-wrap z">
          <img className="el z" src={gif}></img>
        </div>
      </div>
      <div className='bounce32'>
        <div className="lol-wrap z">
          <img className="el z" src={buy}></img>
        </div>
      </div>
      <div className='bounce33'>
        <div className="le-wrap x">
          <img className="le x" src={eth}></img>
        </div>
      </div>
      <div className='bounce34'>
        <div className="le-wrap z">
          <img className="le x" src={stonks}></img>
        </div>
      </div>
      <div className='bounce35'>
        <div className="lol-wrap y">
          <img className="le z" src={money}></img>
        </div>
      </div>
      <div className='bounce36'>
        <div className="lol-wrap z">
          <img className="le y" src={snort}></img>
        </div>
      </div>
      <body>
      <div className='welcome'>
        <Modal show={modalVisible} onClose={closeModal} />
      </div>
      {audioPlaying && (
      <audio autoPlay loop>
        <source src={audioFile} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
      )}
      <h1 className='top front'>$BINGUS</h1>
        <div className='container front'>
          <a className='card'>
            <img src={logo} className="App-logo" alt="logo" width='500vw'/>
          </a>
        </div>
        <h1 className='top front'>BINGUS ON SOLANA</h1>
        <header className="App-header">
        <div className='container front'>
          <div className='item'>
            <a href='https://solscan.io/tx/zmpk8UTYqh9SaddqavEumsvsAapWfSMC8xQXNYEY1SkUwQJSJ4fiQcJ2rv2jXJSnEss3p4AS1ddYT9EqsDU4M1F'><img src={es} width='120px'/></a>
          </div>
          <div className='item'>
            <a href='https://t.me/BingusSolana'><img src={tg} width='120px'/></a>
          </div>
          <div className='item'>
            <a href='https://twitter.com/solanabingus'><img src={tw} width='120px'/></a>
          </div>
          <div className='item'>
            <a href='https://raydium.io/swap/?inputCurrency=sol&outputCurrency=AQuuQ4xktyzGBFnbKHnYsXHxsKVQetAoiPeCEG97NUJw&fixed=in'><img src={dx} width='105px'/></a>
          </div>
        </div>
      </header>
        <h1 className='bottom front'>GET READY FOR BINGUS</h1>
        <div className='front about'>
            <a href='' className='fairy'><img src={ab} width='300px'/></a>
        </div>
        <h1 className='bottom front'>HE'S ON HIS WAY</h1>
        <img src={td} width='500vw' className='front'/>
        <h1 className='bottom front'> ABOUT <br/><br/> Vladimir Bingus, commonly known as Bingus <br/><br/> held a prominent position as the communist leader of Bingustan from 2010 to 2024 <br/><br/> however, his legacy is tainted by suspicions of being responsible for the death of Chinp Floppa <br/><br/> Get Ready to Flex Your Finances with $BINGUS <br/> <br/> and despite surviving an attack on January 6th, 2021 <br/> <br/> he emerged from the incident a changed Cat. <br/> </h1>
        <img src={db} width='500vw' className='front'/>
        <h1 className='top front'>$BINGUS (moon)</h1>
        <p className='title front'>0/0 TAX, CONTRACT RENOUNCED, LP BURNED. <br/> true community token</p>
        <p className='fairy'>Let's get these gainzzzzzz</p>
      </body>
    </div>
  );
}

export default App;
